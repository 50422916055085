import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Icon } from '@dv01-inc/waterfall-ui';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2>{`Icons`}</h2>
    <PageDescription mdxType="PageDescription">
  Icons are visual symbols used to represent ideas, objects, or actions. They communicate messages at a glance, afford interactivity, and draw attention to important information.
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">About</AnchorLink>
      <AnchorLink mdxType="AnchorLink">File Paths</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Types</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Iconography Groups</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Sizing</AnchorLink>
    </AnchorLinks>
    <h2>{`About`}</h2>
    <p>{`Icons are all SVGs from FontAwesome. There are a few exceptions to this rule, and those icons are typically created from two or more existing FontAwesome SVGs.`}</p>
    <p>{`The spirit of dv01 icon’s tend toward lightweight, streamlined choices. When choosing icons, the best option is typically the “Light” version offered by FontAwesome.`}</p>
    <p><strong parentName="p">{`A note about designing`}</strong>{`: it is preferable to use a FontAwesome icon for symbols such as $ or %, as opposed to using it as text. This ensures that any updates to dv01’s fonts do not also require updating icons, and that users on different operating systems experience less variability in dv01’s UI.`}</p>
    <p>{`Furthermore, it is preferable to use an SVG image when designing in Sketch files, as opposed to using the FontAwesome symbol font. This ensures we can keep all symbols up to date across files, and allows multiple designers to seamlessly work on the same files.`}</p>
    <h2>{`File Paths`}</h2>
    <p>{`Icons may be named for the appearance of the icon, or for the function of the icon. A list of the origins of every icon can be found in `}<a parentName="p" {...{
        "href": "zpl://screen?pid=5d935e306990b116f4960dcb&sid=5f1b3f4442cc6538c72ed39b"
      }}>{`Zeplin`}</a>{`.`}</p>
    <h2>{`Types`}</h2>
    <h3>{`Navigation Icons`}</h3>
    <p>{`Navigation icons represent product lines and overall app functionality. They can be found on the L1 and L2 nav levels, as well as in breadcrumbs.`}</p>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="ball-pile" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Securitizations
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="ball-pile" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="briefcase" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Portfolio Management
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="briefcase" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="puzzle-piece" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Intelligence
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="puzzle-piece" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="landmark" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Credit Facilities
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="landmark" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="database" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Data Direct
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="database" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <span className="fa-layers fa-fw">
      <Icon icon="search" mdxType="Icon" />
      <Icon icon="chart-bar" transform="shrink-8 left-1 up-1" mdxType="Icon" />
    </span>
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Market Surveillance
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<span className="fa-layers fa-fw">
  <Icon icon="search" />
  <Icon icon="chart-bar" transform="shrink-8 left-1 up-1" />
</span>
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="home-lg-alt" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Home
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="home-lg-alt" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="th-list" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Admin
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="th-list" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="user-secret" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Impersonate
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="user-secret" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="cog" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Buckets
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="cog" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="exchange" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Switch Environments
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="exchange" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <span className="fa-layers fa-fw">
      <Icon icon="circle" transform="shrink-10 right-3 up-3" color="success" mdxType="Icon" />
      <Icon icon="bell" mdxType="Icon" />
    </span>
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Notifications-Unread
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<span className="fa-layers fa-fw">
  <Icon icon="circle" transform="shrink-15 left-1 up-1" color="success" />
  <Icon icon="bell" />
</span>
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="bell" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Notifications
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="bell" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="question-circle" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Help
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="question-circle" />
`}</code></pre>
  </Column>
    </Row>
    <Row mdxType="Row">
  <Column colMd={1} colLg={1} mdxType="Column">
    <Icon icon="sign-out" mdxType="Icon" />
  </Column>
  <Column colMd={2} colLg={2} mdxType="Column">
Logout
  </Column>
  <Column colMd={9} colLg={9} mdxType="Column">
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`<Icon icon="sign-out" />
`}</code></pre>
  </Column>
    </Row>
### Action Icons
Action icons can be seen throughout the interface and represent specific actions a user can take on any given screen.
    <h3>{`Dual-State Icons`}</h3>
    <p>{`Dual-state icons are a subset of action icons that also function as an on/off switch.`}</p>
    <h3>{`Notification Icons`}</h3>
    <p>{`Notification icons communicate status and alert the user to information.`}</p>
    <h3>{`Display Icons`}</h3>
    <p>{`Display icons are used throughout the interface to enhance communication to the user. They are all FontAwesome SVG’s for extensibility.`}</p>
    <h3>{`Map Icons`}</h3>
    <p>{`Map icons are used in data visualization to help users customize their view.`}</p>
    <h3>{`Other Icons`}</h3>
    <p>{`Other icons that have specific uses with distinct styling and sizing, even though the icons themselves may be duplicated as other types of icons.`}</p>
    <h2>{`Iconography Groups`}</h2>
    <h3>{`Tape Cracker`}</h3>
    <p>{`Tape Cracker uses iconography in ways that other dv01 products do not (though this will change as the app evolves). Each icon represents the results of a given formula or calculation. Some of its icons are borrowed from other parts of the app and can be found under those names (ie, date is the calendar icon).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      